import React from "react";

// antd & css
import { Row, Col } from "antd";
import "../FrontJetDai.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";

const FrontGuide = () => {
  return (
    <div style={{ marginBottom: 50, fontSize: "16px" }}>
      <FrontBreadcrumb
        link={[
          { href: '/about/jetdai', name: "Jet貸" },
          { href: '', name: "商品貸懶人包" }
        ]}
      />
      
      <div>
        <Row justify="center">
          <Col className="jetdai-faq-big-title" span={22}>
            <div className="title">
              <h1 className="front">手機貸、商品貸懶人包</h1>
            </div>
            <hr className="jetdai-faq-divider" />
            <div className="intro">
              <h2 className="front mt-none mb-none">
                商品貸是任何東西都可以申貸嗎？需要交付抵押品嗎？
              </h2>
              <h2 className="front mt-none">
                Jet貸懶人包幫您快速了解借款新趨勢！
              </h2>
            </div>
          </Col>
        </Row>

        <div className="text-left">
          <Row align="center">
            <Col span={23} md={21} sm={21} xs={21}>
              {/* Q1 */}
              <div className="jetdai-faq-title">
                <h2>「商品貸」、「手機貸」是甚麼？</h2>
              </div>
              <div>
                「商品貸」、「手機貸」是一種近年興起的新型分期付款買賣模式。
              </div>
              <div>
                如其名，客戶提供自己名下的指定商品給Jet貸評估，Jet貸會依照您的商品給予對應的額度；顧名思義是一種針對商品售後回購的分期付款方式，現今手機己經成為生活必須用品，且申辦手機貸，無需質押手機，對於有小額資金需求的客戶，是現今最便利的分期方式。
                <span className="highlight-text">分期申辦全部在線上完成，且申辦額度彈性</span>，
                除商品本身價值外，還有自身的加分條件，如收入證明、勞健保、持有信用卡且正常繳款等，都能爭取申辦到更高的分期額度。
              </div>
              <br />
              <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer">
                Jet貸多樣方案選擇，最高額度高達 9萬元！
              </a>

              {/* Q2 */}
              <div className="jetdai-faq-title">
                <h2>有哪些小額貸款的方式可以選擇？</h2>
              </div>
              <div>
                小額貸款有許多管道可以選擇，如銀行信貸及本篇所介紹的民間商品貸、手機貸等，您可以評估自身情況來選擇最適合的管道，
                若無從下手，您也可以諮詢
                <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer">Jet貸專員</a>
                ，我們會協助您找到最優方案。
              </div>

              {/* Q3 */}
              <div className="jetdai-faq-title">
                <h2>商品貸、手機貸適合哪些人？</h2>
              </div>
              <div>
                商品貸、手機貸適用於以下幾個情境，以下情境適用於有小額資金需求的族群，有大額資金需求建議尋求其他管道。申辦前您可以透過專員諮詢來評估是否適合申辦。
              </div>
              <div>
                <div className="list-title">
                  1. 緊急資金需求
                </div>
                商品貸、手機貸申辦審核快速，對於有緊急小額資金需求的族群來說很適合，從申辦到資金撥款入帳最快兩個工作天就能完成。

                <div className="list-title">
                  2. 無信用紀錄、信用不良的族群
                </div>
                如在學學生、社會新鮮人、自由業者等信用小白或有信用不良紀錄的族群，商品貸、手機貸是一個相對審核較寬鬆的借款管道，可安全快速的獲得小額資金。
              </div>

              {/* Q4 */}
              <div className="jetdai-faq-title">
                <h2>商品貸、手機貸有哪些優缺點？</h2>
              </div>
              <div>
                商品貸、手機貸不像銀行信貸，需要走複雜的流程，且有嚴謹的審核條件，下方統整商品貸、手機貸的所有優缺點，讓您一目了然不擔心。
              </div>
              <div className="list-title">
                優點
              </div>
              <ul>
                <li>全線上審核，任何地點都能辦</li>
                <li>審核條件較銀行寬鬆，信用小白也能過</li>
                <li>放款速度快，審核通過一個工作日即撥款入帳</li>
                <li>時間、額度彈性高，可以依自身狀況申辦相應的方案</li>
              </ul>
              <div className="list-title">
                缺點
              </div>
              <ul>
                <li>網路上詐騙多，風險較高</li>
                <li>貸款的利率較銀行信貸高</li>
                <li>放貸額度有限</li>
                <li>部分管道會收取高額代辦手續費</li>
              </ul>
              <br />
              <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer" onClick={() => { return window.gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step') }}>
                Jet貸安心借，不收取高額代辦費用，所有費用公開透明！
              </a>

              {/* Q5 */}
              <div className="jetdai-faq-title">
                <h2>商品貸、手機貸申辦條件是甚麼</h2>
              </div>
              <div>
                商品貸、手機貸申辦的條件相較銀行較為寬鬆，但申辦前建議可參考以下項目，符合越多越能幫助您提高案件的核准率：
              </div>
              <div className="list-title highlight-text">
                必要條件
              </div>
              <ul style={{ marginLeft: -20 }}>
                <li>年滿18歲，為本國國民</li>
                <li>有本人銀行帳戶供撥款<span className="highlight-text">(戶名必須為申辦人姓名)</span></li>
              </ul>
              <div className="list-title">
              加分條件
              </div>
              <ul style={{ marginLeft: -20 }}>
                <li>有穩定工作收入</li>
                <li>有勞健保證明</li>
                <li>持有信用卡且正常繳款</li>
              </ul>

              {/* Q6 */}
              <div className="jetdai-faq-title">
                <h2>商品貸、手機貸如何申辦？</h2>
              </div>
              <div>
                辦理商品貸、手機貸步驟非常簡單，全程線上操作，只需四個步驟就能完成申請。
              </div>
              <div>
                <div className="list-title">
                  1. 專屬專員諮詢
                </div>
                您可以加入Line官方帳號向專員諮詢Jet貸方案，專員會協助您評估目前條件，並提供對應建議及解答。

                <div className="list-title">
                  2. 專員提供專屬方案
                </div>
                諮詢了解後，專員會提供您建議的方案及提供申請連結。

                <div className="list-title">
                  3. 線上填寫資料送件申請
                </div>
                點選申請連結進入申辦畫面，依照顯示畫面填入對應資訊即可送出申請。

                <div className="list-title">
                  4. 審核通過撥款
                </div>
                審核通過後，專員會通知您已完成申辦，申辦額度將於一個工作日後撥款至您的指定帳戶。
              </div>
              <br />
              <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer" onClick={() => { return window.gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step') }}>
                專人諮詢Jet貸方案，快速借款解決困難！
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default FrontGuide;

import React, { useState, useEffect } from "react";

import { Menu, Dropdown, Button, Divider, Space, Drawer } from "antd";

import { MenuOutlined } from "@ant-design/icons";
import "src/components/Front/Header/FrontHeader.css";
import logoText from "src/assets/JetShop_logo_text.png";
import logo from "src/assets/JetShop_logo.png";
import { MEMBER_URL } from "src/config";

const FrontHeader = () => {
	const { SubMenu } = Menu;

	const [drawerVisible, setDrawerVisible] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth <= 891);

	// jet貸活動時間：2024/03/11 00:00:00~2024/04/01 23:59:59(活動結束後網頁才消失)
	const [endDate] = useState("2024/10/31 23:59:59");
	const [endDateActivityShare] = useState("2024/06/30 23:59:59");
	const [isInActivity, setIsInActivity] = useState(false);
	const [isInActivityShare, setIsInActivityShare] = useState(false);

	useEffect(() => {
		let today = new Date();
		let _endDate = new Date(endDate);
		let _endDateActivityShare = new Date(endDateActivityShare);
		setIsInActivity(today <= _endDate);
		setIsInActivityShare(today <= _endDateActivityShare);
  }, []);

	useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();

    return () => window.removeEventListener("resize", onWindowResize);
  }, [windowWidth]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth <= 891);
  };

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const onClose = () => {
    setDrawerVisible(false);
  };

	const installmentMenu = (
		<Menu className="frontSubMenu">
			<Menu.Item key={101}>
				<a href="/installment/guide">我是消費者</a>
			</Menu.Item>
			<SubMenu className="frontSubMenuChild" key={102} title="我是商家">
				<Menu.Item key={103} style={{ height: 50, fontSize: 16 }}>
					<a href="/installment/merchant/guide">合作服務介紹</a>
				</Menu.Item>
				<Menu.Item key={104} style={{ height: 50, fontSize: 16 }}>
					<a href="/ecommerce">Jet捷店</a>
				</Menu.Item>
			</SubMenu>
			<Menu.Item key={105}>
				<a href="/installment/activity">活動介紹</a>
			</Menu.Item>
		</Menu>
	);

	const jetDaiMenu = (
		<Menu className="frontSubMenu">
			<Menu.Item key={201}>
				<a href="/about/jetdai">商品介紹</a>
			</Menu.Item>
			{ isInActivity &&
				<Menu.Item key={202}>
					<a href="/about/jetdai/activity">活動介紹 </a>
				</Menu.Item>
			}
			{ isInActivityShare &&
				<Menu.Item key={203}>
					<a href="/about/jetdai/activity-share">好友推薦獎勵</a>
				</Menu.Item>
			}
		</Menu>
	);

	const merchantMenu = (
		<Menu className="frontSubMenu">
			<Menu.Item key={501}>
				<a href="/merchant/apply">註冊</a>
			</Menu.Item>
			<Menu.Item key={502}>
				<a href="/admin/login">登入</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="frontHeader">
			<div className="frontHeaderLogo">
				<a href="/">
					{
						isSmallSize ? (
							<img src={logo} alt="JetShop Logo" style={{width: "60px"}} />
						) : (
							<img src={logoText} alt="JetShop Logo" style={{width: "220px"}} />
						)
					}
				</a>
			</div>
			{
				isSmallSize ? ([
					<Button
						className="drawerBtn"
						type="text"
						size="large"
						onClick={showDrawer}
						key={0}
					>
						<MenuOutlined style={{ color: "#333333" }} />
					</Button>,
					<Drawer
						className="frontDrawer"
						placement="right"
						onClose={onClose}
						visible={drawerVisible}
						closable={false}
						width={200}
						key={1}
					>
						<Menu
							className="frontDrawerMenu"
							mode="inline"
							theme="dark"
						>
							<SubMenu key={100} title="商品分期">
								<Menu.Item key={101}>
									<a href="/installment/guide">我是消費者</a>
								</Menu.Item>
								<SubMenu key={102} title="我是商家">
									<Menu.Item key={103}>
										<a href="/installment/merchant/guide">合作服務介紹</a>
									</Menu.Item>
									<Menu.Item key={104}>
										<a href="/ecommerce">Jet捷店</a>
									</Menu.Item>
								</SubMenu>
								<Menu.Item key={105}>
									<a href="/installment/activity">活動介紹</a>
								</Menu.Item>
							</SubMenu>
							
							<SubMenu key={200} title="Jet貸">
								<Menu.Item key={201}>
									<a href="/about/jetdai">商品介紹</a>
								</Menu.Item>
								{ isInActivity &&
									<Menu.Item key={202}>
										<a href="/about/jetdai/activity">活動介紹 </a>
									</Menu.Item>
								}
								{ isInActivityShare &&
									<Menu.Item key={203}>
										<a href="/about/jetdai/activity-share">好友推薦獎勵</a>
									</Menu.Item>
									}
							</SubMenu>
							
							<Menu.Item key={300}>
								<a href="/about/faq">常見問題</a>
							</Menu.Item>
							<Menu.Item key={400}>
								<a href="/about/us">關於我們</a>
							</Menu.Item>
							<SubMenu key={500} title="商家註冊/登入">
								<Menu.Item key={501}>
									<a href="/merchant/apply">註冊</a>
								</Menu.Item>
								<Menu.Item key={502}>
									<a href="/admin/login">登入</a>
								</Menu.Item>
							</SubMenu>
							<Menu.Item key={600}>
								<a href={`${MEMBER_URL}`}>消費者中心</a>
							</Menu.Item>
						</Menu>
					</Drawer>
				]) : (
					<div className="frontMenu">
						<Dropdown overlay={installmentMenu} trigger={["click"]}>
							<Space>
								<a>商品分期</a>
							</Space>
						</Dropdown>
						<Divider type="vertical" style={{ backgroundColor: "#fff" }} />
						<Dropdown overlay={jetDaiMenu} trigger={["click"]}>
							<Space>
								<a>Jet貸</a>
							</Space>
						</Dropdown>
						<Divider type="vertical" style={{ backgroundColor: "#fff" }} />
						<a href="/about/faq">常見問題</a>
						<Divider type="vertical" style={{ backgroundColor: "#fff" }} />
						<a href="/about/us">關於我們</a>
						<Divider type="vertical" style={{ backgroundColor: "#fff" }} />
						<Dropdown overlay={merchantMenu} trigger={["click"]}>
							<Space>
								<a>商家註冊/登入</a>
							</Space>
						</Dropdown>
						<Divider type="vertical" style={{ backgroundColor: "#fff" }} />
						<a href={MEMBER_URL}>消費者中心</a>
					</div>
				)
			}
		</div>
	);
}

export default FrontHeader;

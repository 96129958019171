import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

// image
import btnPointer from "src/assets/btn_pointer.png";

const Section3 = () => {
  const steps = [
    {
      id: 1,
      color: "red",
      content: ["確認活動資訊"],
    },
    {
      id: 2,
      color: "orange",
      content: ["加入Line", "Jet貸線上客服"],
    },
    {
      id: 3,
      color: "green",
      content: ["填寫資料", "申請進件"],
    },
    {
      id: 4,
      color: "blue",
      content: ["審核通過", "一個工作天內撥款入帳"],
    },
  ];

  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={20} id="step">
        <FrontDivJcoin title={"　操作步驟　"} />
      </Col>

      <Col span={24}></Col>

      {
        steps.map(item => (
          <Col className="d-flex justify-center" key={item.id} span={12} lg={4} md={6} sm={6} style={{ marginTop: 20}}>
            <div className="jcoinStep">
              <div className="d-flex justify-center">
                <div className={`step ${item.color}`}>
                  {item.id}
                </div>
              </div>
              <div className="content text-center" style={{ marginTop: 20, fontSize: 16 }}>
                {
                  item.content.map((_content, idx) => (
                    <p key={idx} className="mb-none">{ _content }</p>
                  ))
                }
              </div>
            </div>
          </Col>
        ))
      }

      <Col span={24} style={{ marginTop: 70 }}>
        <div className="btn">
          <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer" onClick={() => { return window.gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step') }}>
            <button className="frontBtn orange" style={{ width: 180 }}>
              我要立即申辦
              <img src={btnPointer} alt="pointer" style={{ width: 20, marginLeft: 6 }} />
            </button>
          </a>
        </div>
      </Col>
    </Row>
  );
}

export default Section3;

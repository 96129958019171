import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { bannerService } from "src/services";

// import styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper";

// antd
import { Row, Col } from "antd";

import logo from "src/assets/JetShop_logo_text_en.png";
import IconNew from "src/assets/icon_new.png";

const Section1 = () => {
  const [bannerImgs, setBannerImgs] = useState([]);

  useEffect(() => {
    let isMounted = true;
    
    const data = [];
    bannerService.getFrontBannerImg()
    .then(
      res => {
        res.json().then(result => {
          result.data.forEach(banner => {
            if (banner.bannerDisplay) data.push(banner);
          });
          data.sort((a, b) => {
            return a.bannerOrder - b.bannerOrder;
          });
          if (isMounted) setBannerImgs(data);
        });
      },
      error => {
        console.log(error);
      }
    );
    
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Row className="frontHomeSection1" justify="center">
      <Col className="d-flex justify-center" span={24} style={{ marginTop: 50 }}>
        <div style={{ marginRight: 10 }}>
          <img src={logo} alt="JetShop" className="frontHomeLogo" />
        </div>
        <div style={{ lineHeight: 1 }}>
          <h1 className="front">先享後付</h1>
        </div>
      </Col>
      <Col span={24}>
        <h2 className="front" style={{ margin: "-20px 0 0 0" }}>
          <span className="redText">20</span> 分鐘快速審核 • 
          <span className="redText">0</span> 負擔分期付款
        </h2>
      </Col>
      <Col span={24} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Row justify="center" gutter={[24, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col>
            <a href="/about/jetdai">
              <button className="frontLandingBtn-light" onClick={() => { return window.gtag_report_conversion('https://jetshop.tw/about/jetdai') }}>
                <img className="new-icon" src={IconNew} alt="new" />
                {"小額資金安心首選，20分鐘審件完成 >"}
              </button>
            </a>
          </Col>
          <Col>
            <a href="/installment/merchant/guide">
              <button className="frontLandingBtn-dark">
                {"高價商品0元購物，無負擔輕鬆買 >"}
              </button>
            </a>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginBottom: 70 }}>
        { bannerImgs.length > 0 &&
          <Swiper 
            className="frontSwiper"
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={100}
            navigation={true} 
            loop={true}
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {
              bannerImgs.map(item => (
                <SwiperSlide key={item.bannerNo}>
                  {item.bannerUrl
                    ? (
                      <a href={item.bannerUrl}>
                        <img src={item.bannerDocImg.url} alt={item.bannerDescription} />
                      </a>)
                    : (
                      <img src={item.bannerDocImg.url} alt={item.bannerDescription} />)
                  }
                </SwiperSlide>
              ))
            }
          </Swiper>
        }
      </Col>
    </Row>
  );
}

export default Section1;

import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import IconNew from "src/assets/icon_new.png";

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          <p>JetShop懂您的需求，</p>
          <p>20分鐘快速審核解決燃眉之急</p>
        </h2>
        <FrontDivCenter />
      </Col>
      <Col className="d-flex justify-center" span={22} md={10} sm={10} style={{ marginTop: 20 }}>
        <div className="frontHomeIntro">
          <div className="d-flex justify-center">
            <h3 className="front frontHomeTitle jetdai">
              <img
                className="new-icon"
                src={IconNew}
                alt="new"
                style={{ left: "-35px", top: '-30px', width: 100 }}
              />  
              Jet貸
            </h3>
          </div>
          <div className="frontHomeSubTitle">
            <h4 className="front">
              <p className="mb-none">手機在手就能輕鬆借，</p>
              <p>最高可申請8萬元</p>
            </h4>
          </div>
          <div className="content">
            <p className="mb-none">20分鐘快速審核，資金救急刻不容緩！</p>
            <p className="mb-none highlight-text">『保證絕不收取代辦費』</p>
            <p>輕鬆帶您渡過資金需求免煩惱！</p>
          </div>
          <a href="/about/jetdai" onClick={() => { return window.gtag_report_conversion('https://jetshop.tw/about/jetdai') }}>
            <button className="frontBtn" style={{ width: 180 }}>
              點我了解更多
            </button>
          </a>
        </div>
      </Col>
      <Col className="d-flex justify-center" span={22} md={10} sm={10} style={{ marginTop: 20 }}>
        <div className="frontHomeIntro">
          <div className="d-flex justify-center">
            <h3 className="front frontHomeTitle installment">
              商品分期
            </h3>
          </div>
          <div className="frontHomeSubTitle">
            <h4 className="front">
              <p className="mb-none">高單價3C商品0元購</p>
              <p>專人審核安全有保障</p>
            </h4>
          </div>
          <div className="content">
            <p className="mb-none">不需信用卡也能分期付款</p>
            <p className="mb-none highlight-text">結合四大超商繳費，親民便利</p>
            <p>無紙化線上申請，快速處理您的需求</p>
          </div>
          <a href="/installment/guide">
            <button className="frontBtn" style={{ width: 180 }}>
              點我了解更多
            </button>
          </a>
        </div>
      </Col>
    </Row>
  );
}

export default Section2;
